import { ReducersMapObject } from 'redux';

import * as Item from '@common/react/store/Item';
import { BaseApplicationState, BaseAppThunkAction, baseReducers } from '@common/react/store';
import { PageItemState, reducer as PageStateReducer } from '@common/react/store/PageItem';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { BuildData } from '@common/react/objects/BuildData';
import BaseHostOptions from '@common/react/store/BaseHostOptions';

import { Location } from '@app/objects/Location';

// The top-level state object
export interface ApplicationState extends BaseApplicationState<BaseUser> {
	serverPage: PageItemState<any>;
	buildData: Item.ItemState<BuildData>;
	hostOptions: Item.ItemState<BaseHostOptions>;

	blogPageId: Item.ItemState<number>;
	location: Item.ItemState<Location>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers: ReducersMapObject = {
	...baseReducers,

	serverPage: PageStateReducer<any>('serverPage'),
	buildData: Item.getReducer<BuildData>('buildData'),
	hostOptions: Item.getReducer<BaseHostOptions>('hostOptions'),

	blogPageId: Item.getReducer<number>('blogPageId'),
	location: Item.getReducer<Location>('location'),
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export type AppThunkAction<TAction> = BaseAppThunkAction<TAction, BaseUser, ApplicationState>
