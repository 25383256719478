import React from 'react';

import { SocialMedia as SocialMediaType } from '@commonTuna/react/objects/SocialMedia';

interface Props {
	socialMedias: Array<SocialMediaType & { title, icon }>;
}

const SocialMedia: React.FC<Props> = ({ socialMedias }) => {
	return <>
		{socialMedias?.filter((media) => media.icon).map((media) => <a
			key={media.id}
			href={media.link}
			title={media.title}
			target="_blank"
			aria-label={media.link}
			style={{ marginRight: 8 }}
			rel="noreferrer"
		>
			<i className={`fa fa-${media.icon}`} />
		</a>)
		}
	</>;
};

export default SocialMedia;
