import React from 'react';
import TagManager from 'react-gtm-module';

import { useCompanySettingsContext } from '@app/components/UI/CompanySettingsProvider';

const Gtm: React.FC = () => {
	const { companySettings: { googleTagManagerId } } = useCompanySettingsContext();

	React.useEffect(() => {
		if (process.env.NODE_ENV === 'production' && googleTagManagerId && googleTagManagerId.trim() !== '') {
			TagManager.initialize({ gtmId: googleTagManagerId });
		}
	}, [googleTagManagerId]);
	return <></>;
};

export default Gtm;
