import * as React from 'react';

import once from 'lodash/once';

export interface NotFoundPageProviderProps {
	notFoundComponent: JSX.Element;
}

export interface NotFoundPageProviderContextState {
	notFoundPage: JSX.Element;
}

export interface NotFoundPageProviderContext {
	state: NotFoundPageProviderContextState;
	actions: { setNotFoundPage };
}

export const createNotFoundPageProviderContext = once(() => React.createContext({} as NotFoundPageProviderContext));

export const useNotFoundPageProviderContext: () => NotFoundPageProviderContext = () => React.useContext(createNotFoundPageProviderContext());

export const NotFoundPageProvider: React.FC<NotFoundPageProviderProps> = ({ children, notFoundComponent }) => {
	const ItemContext = createNotFoundPageProviderContext();

	const [notFoundPage, setNotFoundPage] = React.useState<JSX.Element>(notFoundComponent);

	const value = {
		state: {
			notFoundPage,
		},
		actions: {
			setNotFoundPage,
		},
	};

	return (
		<ItemContext.Provider value={value}>
			{children}
		</ItemContext.Provider>
	);
};
