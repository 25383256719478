import { fetch } from 'domain-task';

import { BaseApplicationState } from '@common/react/store';
import { BaseUser } from '@common/react/objects/BaseUser';
import { BaseParams } from '@common/typescript/objects/BaseParams';

interface Message<T> {
	success: number;
	response: T;
	session: string;
}

export interface ResponseError {
	message: string;
	code: number;
	name?: string;
}

function baseRequest<
	T,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
>(type: string, data: BaseParams = {}, state?: TApplicationState, signal?: AbortSignal): Promise<T> {
	return fetch('api/post', {
		credentials: 'same-origin',
		method: 'POST',
		headers: {
			'Content-type': 'application/json; charset=utf-8',
			Cookie: `session=${state ? state.login.session : ''}`,
		},
		body: JSON.stringify({
			type,
			data: JSON.stringify(data),
		}),
		signal,
	})
		.then((response) => response.json() as Message<T | ResponseError>)
		.then((data: Message<T | ResponseError>) => {
			if (!data.success) {
				throw data.response as ResponseError;
			}

			return data.response as T;
		});
}

function request<
	T,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
	>(type: string, data: BaseParams = {}, state?: TApplicationState, signal?: AbortSignal): Promise<T> {
	return baseRequest<T, TUser, TApplicationState>(type, data, state, signal)
		.catch((error: ResponseError) => {
			if (error.name === 'AbortError') {
				throw new Error('Aborted');
			}
			if (error.message === 'Access denied' && window) {
				window.location.href = '/';
			}

			console.log(error.message);
			throw error.message as string;
		});
}

export { baseRequest, request };
