import * as React from 'react';
import { Switch } from 'react-router-dom';

import loadable from '@loadable/component';

import { loadableDelay, params } from '@common/react/loadable/loadableSettings';
import NotFoundRoute from '@common/react/components/Routes/NotFoundRoute';

import Layout from '@app/components/Layout';
import { MainRoute } from '@app/components/Routes/MainRoute';

const Home = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "HomePage" */ '@app/components/Pages/Home/Home')), params);
const Blog = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BlogPage" */ '@app/components/Pages/Blog/Blog')), params);
const Article = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ArticlePage" */ '@app/components/Pages/Article/Article')), params);

export const routes = (<Layout>
	<Switch>
		<MainRoute exact path="/" component={Home} />
		<MainRoute exact path="/blog" component={Blog} />
		<MainRoute path="/blog/:path" component={Article} />
		<NotFoundRoute path="*" openRoute={MainRoute} />
	</Switch>
</Layout>);
