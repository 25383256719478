import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, NavLink } from 'react-router-dom';

import { phoneFormat } from '@common/react/components/Forms/FormikPhoneControl/FormikPhoneControl';

import { SocialMedia as SocialMediaType } from '@commonTuna/react/objects/SocialMedia';

import SocialMedia from '@app/components/UI/SocialMedia/SocialMedia';
import { ApplicationState } from '@app/store';

interface PassedProps {
	phone?: string;
	socialMedias?: Array<SocialMediaType & { title, icon }>;
}

type HeaderProps = PassedProps;

const Header: React.FC<HeaderProps> = ({ socialMedias: propsSocialMedias, phone: propsPhone }) => {
	const [isMenuOpen, setOpen] = React.useState(false);
	const toggleMenu = React.useCallback(() => setOpen((prev) => !prev), []);
	const close = React.useCallback(() => setOpen(false), []);
	const browserLocation = useLocation();

	const { blogPageId, location } = useSelector((state: ApplicationState) => ({
		blogPageId: state.blogPageId.item,
		location: state.location.item,
	}));

	const mainMenu = React.useMemo(() => {
		const menu = [
			{
				path: '#home', name: 'Home', basePath: '', fullPath: '',
			},
			{
				path: '#info', name: 'About', basePath: '', fullPath: '',
			},
			{
				path: '#booking', name: 'Schedule', basePath: '', fullPath: '', className: 'show-mobile',
			},
			{
				path: '#services', name: 'Services', basePath: '', fullPath: '',
			},
			{
				path: '#blog', name: 'Blog', basePath: '', fullPath: '',
			},
			{
				path: '', name: 'FAQ', basePath: '#faq', fullPath: '#faq',
			},
			{
				path: '#reviews', name: 'Reviews', basePath: '', fullPath: '',
			},
			{
				path: '#contacts', name: 'Contacts', basePath: '', fullPath: '',
			},
		];

		return menu.filter((item) => item.path !== '#blog' || blogPageId > 0);
	}, [blogPageId, browserLocation.pathname]);

	const socialMedias = propsSocialMedias || location.socialMedias;
	const phone = propsPhone || location.phone;

	return <header className="site-header">
		<nav className="navbar navbar-default navbar-fixed-top">
			<div className="container">
				<NavLink to="/" className="logo-wrapper" aria-label="logo">
					<picture>
						<source media="(max-width: 430px)" srcSet="/images/SMMobile2.webp" />
						<source media="(max-width: 768px)" srcSet="/images/SMMobile.webp" />
						<source media="(min-width: 769px)" srcSet="/images/SM.webp" />
						<img alt={`${location.companyName} Logo`} className="header-logo" width="290px" height="80px" srcSet="/images/SM.webp" />
					</picture>
				</NavLink>
				<div className="navbar-left pull-left">
					<div className="navbar-burger__container">
						<button
							type="button"
							className={`btn navbar-burger ${isMenuOpen ? 'navbar-burger__open' : ''}`}
							aria-label="open sidebar"
							onClick={toggleMenu}
						>
							<span />
						</button>
					</div>
					<ul className={`menu-component menu-component${isMenuOpen ? '__open' : '__close'}`}>
						{mainMenu.map((item, i) =>
							<li key={i} className={`menu-component__item ${item.className ? item.className : ''}`}>
								<a
									onClick={close}
									href={item.basePath + item.path}
									className="menu-component__item-link"
								>
									{item.name}
								</a>
							</li>)
						}
						{socialMedias ? <li key="socialMedias" className="social-medias show-mobile">
							<SocialMedia socialMedias={socialMedias} />
						</li> : null}
					</ul>
				</div>
				<div className="pull-right navbar-right">
					{socialMedias ? <SocialMedia socialMedias={socialMedias} /> : null}
					{phone
						&& <a
							className="phone-link"
							href={`tel:${phone}`}
							key="phone"
							title={`Call ${location.companyName} Clinic`}
						>
							<i className="fa fa-phone" style={{ marginRight: 8 }} />
							<span className="phone-number">{phoneFormat(phone)}</span>
						</a>
					}
				</div>
			</div>
		</nav>
	</header>;
};

export default Header;
