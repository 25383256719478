import * as React from 'react';

import once from 'lodash/once';

export interface LoadingProviderProps {
	loader: JSX.Element;
}

export interface LoadingProviderContextState {
	pageLoader: JSX.Element;
}

export interface LoadingProviderContext {
	state: LoadingProviderContextState;
	actions: {setPageLoader};
}

export const createLoaderProviderContext = once(() => React.createContext({} as LoadingProviderContext));

export const useLoaderProviderContext: () => LoadingProviderContext = () => React.useContext(createLoaderProviderContext());

export const LoadingProvider: React.FC<LoadingProviderProps> = ({ children, loader }) => {
	const ItemContext = createLoaderProviderContext();

	const [pageLoader, setPageLoader] = React.useState<JSX.Element>(loader);

	const value = {
		state: {
			pageLoader,
		},
		actions: {
			setPageLoader,
		},
	};

	return (
		<ItemContext.Provider value={value}>
			{children}
		</ItemContext.Provider>
	);
};
