import * as React from 'react';
import MaskedInput from 'react-text-mask';

import { FieldProps } from 'formik';

export const defaultPhoneMask = ['+', '1', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const allCountriesPhoneMask = [
	'+',
	/[1-9]/,
	' ',
	'(',
	/\d/,
	/\d/,
	/\d/,
	')',
	' ',
	/\d/,
	/\d/,
	/\d/,
	'-',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
];

export const allCountriesPhoneMask2DigitCode = [
	'+',
	/[1-9]/,
	/[1-9]/,
	' ',
	'(',
	/\d/,
	/\d/,
	/\d/,
	')',
	' ',
	/\d/,
	/\d/,
	/\d/,
	'-',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
];

export const getAllCountriesPhoneMask = (phone: string | null | undefined) => {
	if (!phone || phone.includes('_')) {
		return defaultPhoneMask;
	}

	const formatPhone = phoneFormat(phone);

	const matches = formatPhone.match(/^(\+[1-9]{1,2})/);

	if (matches && matches[0] && matches[0].length > 2) {
		return allCountriesPhoneMask2DigitCode;
	}

	return allCountriesPhoneMask;
};

export const phoneReplace = (phone: string | null | undefined): string => (phone
	? phone.replace(/[\(\)\-\s]/g, '')
	: '');

export const phoneFormat = (phone: string | null | undefined): string => (phone
	? phone.replace(/\+([1-9]{1,2})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4')
	: '');

interface Props {
	placeholder?: string;
	fieldProps: FieldProps;
	className?: string;
	mask?: Array<string | RegExp>;
	withReplace?: boolean;
	isMobile?: boolean;
	disabled?: boolean;
	id?: string;
	autoComplete?: string;
}

const removeDefaultPhoneMask = (phone: string | null | undefined): string => phoneReplace(phone)
	.replace(/^\+?1/, '')
	.replace(/\D/g, '')
	.replace(/_/g, '');

const getMask = (count, str, char: string = '_') => {
	const length = str ? count - str.length : count;
	return `${str || ''}${Array.from({ length }).fill(char).join('')}`;
};

const defaultPhoneFormat = (value) => {
	let phone = (value || '').replace(/\D/g, '');
	const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);

	if (match) {
		phone = `(${getMask(3, match[1])}) ${getMask(3, match[2])}-${getMask(3, match[3])}`;
	}
	return phone;
};

export const FormikPhoneControl: React.FC<Props> = ({
	placeholder = '',
	fieldProps: { field, form },
	className = 'form-control',
	mask = defaultPhoneMask,
	withReplace = true,
	isMobile = false,
	disabled,
	id,
	autoComplete,
}) => {
	const value = React.useMemo(() => phoneFormat(field.value), [field.value]);

	const pipe = (text, t) => {
		const needReplace = mask === defaultPhoneMask && t.rawValue?.replace(/\D/g, '').length <= 10
			&& removeDefaultPhoneMask(text) !== removeDefaultPhoneMask(t.rawValue);

		return needReplace ? `+1 ${defaultPhoneFormat(removeDefaultPhoneMask(t.rawValue))}` : text;
	};

	return <MaskedInput
		inputMode="numeric"
		type={isMobile ? 'tel' : 'text'}
		mask={mask}
		className={className}
		placeholder={placeholder}
		pipe={pipe}
		guide
		id={id || field.name}
		onChange={(e) => form.setFieldValue(field.name, withReplace ? phoneReplace(e.target.value) : e.target.value)}
		onBlur={field.onBlur}
		value={value}
		disabled={disabled}
		autoComplete={autoComplete}
	/>;
};
