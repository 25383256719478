import * as React from 'react';
import { useSelector } from 'react-redux';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import SocialMedia from '@app/components/UI/SocialMedia/SocialMedia';
import { ApplicationState } from '@app/store';

const year = new Date().getFullYear();

const Footer: React.FC = () => {
	const socialMedias = useSelector((state: ApplicationState) => state.location.item.socialMedias);

	return <footer className="site-footer">
		<div className="content-block">
			<div className="site-footer__copyright pull-left">
				©
				{' '}
				{year}
				{' '}
				NorthernTuna, all rights reserved
			</div>
			<a target="_blank" href="https://www.northerntuna.com/" className="nt-logo pull-right" rel="noreferrer">
				<ImageLazy width="350" height="62" src="/images/nt-logo.png" alt="Northern Tuna logo" />
			</a>
			{socialMedias ? <div className="show-mobile pull-left footer_social-media">
				<SocialMedia socialMedias={socialMedias} />
			</div> : null}
		</div>
	</footer>;
};

export default Footer;
