import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import Header from '@app/components/UI/Header/Header';
import Footer from '@app/components/UI/Footer/Footer';

import '@app/scss/pages/home.scss';

interface Props extends RouteProps {
	component: any;
	withoutHeader?: boolean;
	withoutFooter?: boolean;
}

export const MainRoute: React.FC<Props> = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			<div className="home-page">
				{!rest.withoutHeader && <Header />}
				<Component {...props} />
				{!rest.withoutFooter && <Footer />}
			</div>
		}
	/>
);
