import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { UnregisterCallback } from 'history';

import '@common/react/scss/components/error.scss';

export class ErrorBoundary extends React.Component<RouteComponentProps<object>, {hasError: boolean}> {
	unlisten: UnregisterCallback | null = null;

	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentWillUnmount() {
		this.unlisten && this.unlisten();
	}

	componentDidMount() {
		this.unlisten = this.props.history.listen((location, action) => {
			if (this.state.hasError) {
				this.setState({ hasError: false });
			}
		});
	}

	componentDidCatch(error, errorInfo) {
		this.setState({ hasError: true });
	}

	render() {
		if (this.state.hasError) {
			return <div className="error-component">
				<div className="error-component__content">
					<i className="fa fa-exclamation-triangle" aria-hidden="true" />
					<br />
					Oops!!!
					{' '}
					<br />
					{' '}
					Something went wrong
				</div>
			</div>;
		}

		return this.props.children;
	}
}

export default withRouter(ErrorBoundary);
